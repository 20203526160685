<gm-action
  [action]="action"
  [matMenuTriggerFor]="matmenu"
  (actionClick)="trigger?.openMenu()"
></gm-action>

<mat-menu #matmenu="matMenu">
  @for (item of menu; track item.id) {
    <button
      mat-menu-item
      (click)="onClick(item)"
      class="menu-item"
      [disabled]="item.disabled"
    >
      @if (item.icon) {
        <gm-icon [name]="item.icon"></gm-icon>
      }
      <span class="label">{{ item.name }}</span>
    </button>
  }
</mat-menu>
