import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
} from '@angular/core';
import { Nil } from '@model';
import { IconComponent, IconName, IconSize } from '@ui/icon';
import { isNil } from 'lodash-es';

import { FabIconColor } from './fab-icon.types';

@Component({
  selector: 'gm-fab-icon',
  templateUrl: './fab-icon.component.html',
  styleUrls: ['./fab-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IconComponent],
})
export class FabIconComponent implements OnChanges {
  @Input() public name: IconName | Nil;

  @HostBinding('class')
  @Input()
  public color: FabIconColor | Nil;

  @Input() public size: IconSize = IconSize.Xlarge;

  /**
   * Size of the rim around icon
   */
  @Input() public rimSize: IconSize | Nil;

  /**
   * Calculated rim around the icon
   */
  public rimMargin = 0;

  public ngOnChanges(): void {
    if (isNil(this.rimSize)) {
      this.rimMargin = this.size / 2;
    } else {
      this.rimMargin = this.rimSize;
    }
  }
}
