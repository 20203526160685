@if (topDivider) {
  <mat-divider
    role="separator"
    class="mat-divider blui-info-list-item-divider mat-divider-horizontal ng-star-inserted"
    aria-orientation="horizontal"
  ></mat-divider>
}
<blui-info-list-item
  [divider]="divider || defaultDivider"
  [hidePadding]="!icon && !action"
  [dense]="dense"
  [wrapSubtitle]="wrapSubtitle"
  [class.has-chevron]="hasChevron"
  [statusColor]="statusColor ?? 'transparent'"
>
  @if (icon && !action) {
    <gm-icon
      blui-icon
      [name]="icon.name"
      [size]="icon.size || defaultIconSize"
      [tooltip]="icon.tooltip"
      [color]="icon.color"
      class="gm-list-item-icon"
    ></gm-icon>
  }
  @if (action) {
    <gm-action [action]="action" blui-icon></gm-action>
  }
  @if (!action && !icon && customIcon) {
    <span blui-icon>
      <ng-content select="[gm-icon]"></ng-content>
    </span>
  }
  <div blui-title class="title">
    <ng-content select="[gm-title]"></ng-content>
  </div>
  <div blui-subtitle class="subtitle">
    <ng-content select="[gm-subtitle]"></ng-content>
  </div>
  <div blui-right-content>
    <ng-content select="[gm-right-content]"></ng-content>
    @if (chevronIcon) {
      <gm-icon
        [name]="chevronIcon.name"
        [size]="chevronIcon.size"
        class="chevron-icon"
      ></gm-icon>
    }
  </div>
</blui-info-list-item>
