import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Nil } from '@model';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
  standalone: true,
  selector: 'gm-lottie-animation',
  templateUrl: './lottie-animation.component.html',
  imports: [LottieComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LottieAnimationComponent {
  @Input() public set animationPath(path: string | Nil) {
    this.options = {
      path: `${path}`,
    };
  }
  @Input() public set animationWidth(width: number | Nil) {
    this.width = `${width}px`;
  }
  @Input() public set animationHeight(height: number | Nil) {
    this.height = `${height}px`;
  }

  public options: AnimationOptions = {
    path: '',
  };
  public width = '';
  public height = '';
}
