import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { Nil } from '@model';
import { isNil } from 'lodash-es';

import { TooltipComponent } from './tooltip.component';

@Directive({ selector: '[gmFocusTooltip]' })
export class FocusTooltipDirective implements OnInit {
  public constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
  ) {}

  @Input('gmFocusTooltip') public text = '';

  private overlayRef: OverlayRef | Nil;

  @HostListener('focus')
  public show() {
    if (isNil(this.overlayRef) || isNil(this.text)) {
      return;
    }

    const tooltipRef = this.overlayRef.attach(
      new ComponentPortal(TooltipComponent),
    );

    tooltipRef.instance.text = this.text;
  }

  @HostListener('blur')
  public hide() {
    this.overlayRef?.detach();
  }

  public ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
          offsetX: 24,
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
    });
  }
}
