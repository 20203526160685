import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { isNotNil } from '@core/is-not-nil';
import { Nil } from '@model';
import { ProgressSpinnerComponent } from '@ui/progress-spinner';
import { first, isNil, last } from 'lodash-es';

import { Loading, RouteLoading } from './loading.types';

@Component({
  selector: 'gm-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ProgressSpinnerComponent],
})
export class LoadingComponent implements AfterViewInit, OnDestroy {
  public constructor(private elementRef: ElementRef<HTMLElement>) {}

  @ViewChild('overlay') private set overlay(
    overlay: ElementRef<HTMLElement> | Nil,
  ) {
    this._overlay = overlay;
    this.toggleDisplay();
  }

  @Input() public set loading(loading: Loading | Nil) {
    this._loading = loading;
    this.toggleDisplay();
  }

  @Input() public set routeLoading(loading: RouteLoading | Nil) {
    this._routeLoading = loading;
    this.toggleDisplay();
  }

  private _loading: Loading | Nil = undefined;
  private _routeLoading: RouteLoading | Nil = undefined;
  private _overlay: ElementRef<HTMLElement> | Nil;
  private observer: MutationObserver | Nil;

  public ngAfterViewInit(): void {
    // we observe change in the dom to detect
    // to make sure the loading is properly placed
    this.observer = new MutationObserver(() => {
      const element = this.getParent(this.elementRef.nativeElement);
      // if the topest parent is not HTML it means the element is detached
      if (element.tagName.toLowerCase() !== 'html') {
        this.toggleDisplay();
      }
    });

    this.observer.observe(this.elementRef.nativeElement, {
      attributes: true,
      childList: true,
      characterData: true,
    });
  }

  public ngOnDestroy(): void {
    this.observer?.disconnect();
  }

  private toggleDisplay(): void {
    if (isNil(this._loading) && isNotNil(this._routeLoading)) {
      const outlets = document.getElementsByClassName('router-outlet');
      const outlet = this.getOutlet(outlets);
      if (isNotNil(outlet)) {
        if (!outlet.contains(this.elementRef.nativeElement)) {
          outlet.appendChild(this.elementRef.nativeElement);
        }

        if (isNotNil(this._overlay)) {
          this._overlay.nativeElement.style.opacity = '1';
        }

        this.elementRef.nativeElement.style.display = 'block';
      }
    } else if (isNotNil(this._loading)) {
      if (!document.body.contains(this.elementRef.nativeElement)) {
        document.body.appendChild(this.elementRef.nativeElement);
      }

      if (isNotNil(this._overlay)) {
        this._overlay.nativeElement.style.opacity =
          this._loading === Loading.App ? '1' : '0.5';
      }
      this.elementRef.nativeElement.style.display = 'block';
    } else {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }

  private getParent(element: HTMLElement): HTMLElement {
    if (element.parentElement) {
      return this.getParent(element.parentElement);
    }
    return element;
  }

  private getOutlet(outlets: HTMLCollectionOf<Element>): HTMLElement {
    if (this._routeLoading === RouteLoading.Tab) {
      return last(outlets) as HTMLElement;
    } else {
      return first(outlets) as HTMLElement;
    }
  }
}
