import { Environment } from './environment.types';

export const environment: Environment = {
  urls: {
    api: '/api/v1',
    supportEmail: 'BGTechSupport@eaton.com',
  },
  schedulerLicenseKey: '0536838352-fcs-1683300359',
  maxPermissionsPerDevice: 100,
  version: '1.3',
  versionSeason: 'Autumn 2023',
  versionTimestamp: parseInt('1717490703019', 10),
  production: false,
  local: false,
  googleTagManagerId: 'GTM-TZ6JSWRB',
};
