import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Nil } from '@model';

import { TooltipModule, TooltipPosition } from '@ui/tooltip';
import { IconColor, IconName, IconSize } from './icon.types';

@Component({
  selector: 'gm-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule, TooltipModule],
})
export class IconComponent {
  @Input() public set name(name: IconName | Nil) {
    this.iconName = name;
    this.isBluiIcon = name?.startsWith('blui-') || false;
    this.isGmIcon = name?.startsWith('gm-') || false;
  }
  @Input() public counter: number | Nil;
  @Input() public set size(size: IconSize | Nil) {
    this.styles = this.getStyles(size || IconSize.Default);
  }

  @HostBinding('class')
  @Input()
  public color: IconColor | Nil;

  @Input() public tooltip: string | Nil;
  @Input() public tooltipPosition: TooltipPosition = 'bottom';

  public iconName: string | Nil;

  public isBluiIcon = false;
  public isGmIcon = false;

  public styles: Record<string, string | number> = this.getStyles(
    IconSize.Default,
  );

  private getStyles(size: IconSize): Record<string, string | number> {
    return {
      'font-size': `${size}rem`,
      'width.rem': size,
      'height.rem': size,
    };
  }
}
