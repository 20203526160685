import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

import {
  ProgressSpinnerPosition,
  ProgressSpinnerSize,
} from './progress-spinner.types';

@Component({
  selector: 'gm-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatProgressSpinnerModule, CommonModule],
})
export class ProgressSpinnerComponent {
  @HostBinding('class')
  @Input()
  public size = ProgressSpinnerSize.Medium;

  @HostBinding('style.justify-content')
  @Input()
  public align = ProgressSpinnerPosition.Center;
}
