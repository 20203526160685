import { Dialog } from '@angular/cdk/dialog';
import { Overlay, PositionStrategy } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { isNotNil } from '@core/is-not-nil';
import { isArray, isNil } from 'lodash-es';

import { DialogComponent } from './dialog.component';
import { DialogConfig, DialogPosition } from './dialog.types';
import { ANIMATION_IN } from './dialog.utils';

export const DEFAULT_DIALOG_MAX_WIDTH = 440;

@Injectable()
export class DialogService {
  public constructor(
    private overlay: Overlay,
    private dialog: Dialog,
  ) {}

  public openDialog<T>(config: DialogConfig<T>) {
    this.dialog.open<unknown, DialogConfig<T>, DialogComponent>(
      DialogComponent,
      {
        autoFocus: false,
        data: config,
        disableClose: true,
        hasBackdrop: true,
        maxWidth: config.maxWidth || DEFAULT_DIALOG_MAX_WIDTH,
        minWidth: config.minWidth,
        panelClass: this.getPanelClass(config),
        viewContainerRef: config.viewContainerRef,
        positionStrategy: this.getPositionStrategy(config),
      },
    );
  }

  public getPanelClass<T>(
    config: DialogConfig<T>,
  ): string | string[] | undefined {
    const position = config.position || DialogPosition.Center;

    const classes = ['gm-dialog', position, 'animate__animated'];

    classes.push(ANIMATION_IN[position]);

    if (isNotNil(config.panelClass)) {
      if (isArray(config.panelClass)) {
        config.panelClass.forEach((c) => {
          classes.push(c);
        });
      } else {
        classes.push(config.panelClass);
      }
    }

    if (isNil(config.confirmAction) && isNil(config.cancelAction)) {
      classes.push('no-action');
    }

    if (isNil(config.title)) {
      classes.push('no-title');
    }

    return classes;
  }

  private getPositionStrategy<T>(
    config: DialogConfig<T>,
  ): PositionStrategy | undefined {
    if (config.position === DialogPosition.RightSide) {
      return this.overlay.position().global().top('0px').right('0px');
    }
    return undefined;
  }
}
