@if (svgName) {
  <svg-icon [name]="svgName" [svgStyle]="svgStyle"></svg-icon>
}
@if (src) {
  <img
    [src]="src"
    [style.height]="imgHeight"
    (load)="onImageLoad($event)"
    alt="image"
  />
}
@if (showLoadingSpinner) {
  <gm-progress-spinner></gm-progress-spinner>
}
