import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { Nil } from '@model';

import { TooltipPosition } from './tooltip.types';

@Component({
  selector: 'gm-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('tooltip', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 })),
      ]),
      transition(':leave', [animate(300, style({ opacity: 0 }))]),
    ]),
  ],
})
export class TooltipComponent {
  @Input() public text: string | Nil;
  @Input() public set position(data: TooltipPosition) {
    this.tooltipPosition = data;
  }

  @HostBinding('class') public tooltipClasses =
    'animate__animated animate__zoomIn';

  public tooltipPosition: TooltipPosition = 'right';
}
