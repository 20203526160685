import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { isNotNil } from '@core/is-not-nil';
import { Id, Nil } from '@model';
import { CardComponent } from '@ui/card';
import { CheckboxComponent } from '@ui/checkbox';
import { isNil } from 'lodash-es';
import { CookieService } from 'ngx-cookie-service';

import { Action, ActionAlign, ActionColor, ActionComponent } from '../action';
import { CookieConsentModule } from './cookie-consent.module';
import { CookieConsentService } from './cookie-consent.service';
import {
  CookieConsent,
  CookieConsentMessages,
  CookieConsentPosition,
} from './cookie-consent.types';

@Component({
  selector: 'gm-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ActionComponent,
    CardComponent,
    CheckboxComponent,
    CommonModule,
    CookieConsentModule,
    FormsModule,
  ],
  providers: [CookieService],
})
export class CookieConsentComponent implements OnChanges {
  public constructor(private service: CookieConsentService) {}

  @Input() public consents: CookieConsent[] | Nil;
  @Input() public messages: CookieConsentMessages | Nil;
  @Input() public userId: Id | Nil;

  @HostBinding('class')
  @Input()
  public position: CookieConsentPosition | Nil =
    CookieConsentPosition.BottomLeft;

  @Output() public accept = new EventEmitter<CookieConsent[]>();

  public currentConsents: CookieConsent[] = [];
  public showSettings = false;
  public acceptCookieAction: Action | Nil;
  public settingsAction: Action | Nil;

  public ngOnChanges({ messages }: SimpleChanges): void {
    if (isNotNil(this.consents) && isNotNil(this.userId)) {
      this.currentConsents = this.getConsents(this.consents, this.userId);
    }
    if (isNotNil(messages)) {
      this.acceptCookieAction = this.getAcceptCookieAction(this.messages);
      this.settingsAction = this.getSettingsAction(this.messages);
    }
  }

  public onCensentToggle({ id }: CookieConsent, accepted: boolean | Nil): void {
    this.currentConsents = this.currentConsents.map((consent) => {
      if (consent.id === id) {
        return { ...consent, accepted: accepted || false };
      }
      return consent;
    });
  }

  private getAcceptCookieAction(messages: CookieConsentMessages | Nil): Action {
    return {
      id: 'accept-cookie',
      name: messages?.accept,
      raised: true,
      color: ActionColor.Primary,
      fullWidth: true,
      align: ActionAlign.Center,
      callback: () => {
        this.service.saveConsent(this.currentConsents, this.userId);
        this.accept.emit(this.currentConsents);
      },
    };
  }

  private getSettingsAction(messages: Nil | CookieConsentMessages): Action {
    return {
      id: 'settings',
      name: messages?.settings,
      basic: true,
      border: true,
      fullWidth: true,
      align: ActionAlign.Center,
      callback: () => {
        this.showSettings = !this.showSettings;
      },
    };
  }

  private getConsents(consents: CookieConsent[], userId: Id) {
    return (consents || []).map((consent) => {
      const value = this.service.getCookieValue(consent, userId);
      return {
        ...consent,
        accepted: isNil(value) ? consent.accepted : value,
      };
    });
  }
}
