import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  forwardRef,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { Nil } from '@model';
import {
  AbstractFormFieldComponent,
  CONTROL_CONTAINER_VIEW_PROVIDER,
} from '@ui/form';

@Component({
  selector: 'gm-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: AbstractFormFieldComponent,
      useExisting: forwardRef(() => {
        return CheckboxComponent;
      }),
    },
  ],
  viewProviders: [CONTROL_CONTAINER_VIEW_PROVIDER],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatFormFieldModule,
  ],
})
export class CheckboxComponent extends AbstractFormFieldComponent<boolean> {
  @Input() public indeterminate: boolean | Nil;
}
