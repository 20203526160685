<gm-card>
  <div class="card-content">
    <div class="title">{{ messages?.title }}</div>
    <div>
      <div class="explanation">{{ messages?.explanation }}</div>
      <a [href]="messages?.linkUrl" class="link" target="_blank">{{
        messages?.link
      }}</a>
    </div>
    @if (showSettings) {
      <div class="settings-container">
        <div class="settings-message">{{ messages?.consent }}</div>
        <div class="settings">
          <gm-checkbox [value]="true" [disabled]="true">
            {{ messages?.required }}
          </gm-checkbox>
          @for (consent of currentConsents; track consent.id) {
            <gm-checkbox
              [value]="consent.accepted"
              (valueChange)="onCensentToggle(consent, $event)"
            >
              {{ consent.name }}
            </gm-checkbox>
          }
        </div>
      </div>
    }
    <div class="actions">
      <div class="left"><gm-action [action]="settingsAction"></gm-action></div>
      <div class="right">
        <gm-action [action]="acceptCookieAction"></gm-action>
      </div>
    </div>
  </div>
</gm-card>
